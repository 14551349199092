<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="container mx-auto flex flex-wrap" v-if="!isLoading">
      <div class="w-10/12 md:w-7/12 mx-auto">
        <h1 class="text-2xl my-5">
          {{ vacancySingle.office }}
          <span class="text-lg text-gray-600"
            >• {{ vacancySingle.vacancies_available }} oportunidade</span
          >
        </h1>
        <div class="flex flex-wrap justify-start items-center my-4">
          <p class="flex mr-3 text-sm">
            <span class="mr-1"
              ><i class="ri-map-pin-2-fill text-green-500"></i
            ></span>
            {{ vacancySingle.neighborhood }}, {{ vacancySingle.city.name }} -
            {{ vacancySingle.state }}
          </p>
          <!-- <p class="flex mr-3 text-sm">
            <span class="mr-1"><i class="ri-todo-fill text-green-500"></i></span
            >Auxiliar/Operacional
          </p> -->
          <p class="flex mr-3 text-sm" v-if="vacancySingle.salary_until != ''">
            <span class="mr-1"
              ><i class="ri-refund-2-fill text-green-500"></i></span
            >Entre {{ vacancySingle.salary }} até
            {{ vacancySingle.salary_until }}
          </p>
          <p class="flex mr-3 text-sm" v-else>
            <span class="mr-1"
              ><i class="ri-refund-2-fill text-green-500"></i></span
            >{{ vacancySingle.salary }}
          </p>
          <p class="flex mr-3 text-sm">
            <span class="mr-1"
              ><i class="ri-survey-fill text-green-500"></i></span
            >Regime {{ vacancySingle.contracting_regime }}
          </p>
        </div>
        <hr class="w-5/6" />
        <div class="my-6">
          <h3 class="text-xl mb-3">Detalhes da oportunidade</h3>
          <span class="text-lg text-slate-700 "
            >Informaçoes sobre a Empresa:</span
          >
          <div class="flex justify-between flex-wrap">
            <p class="flex mb-6">
              <i class="mr-1 text-green-500 ri-building-4-fill"></i>
              <a
                :href="vacancySingle.site"
                target="_blank"
                rel="noopener noreferrer"
                >{{ vacancySingle.company_name }}</a
              >
            </p>
            <p class="flex mb-6" v-if="vacancySingle.email">
              <i class="mr-1 text-green-500 ri-mail-fill"></i>
              <a :href="'mailto:' + vacancySingle.email">{{
                vacancySingle.email
              }}</a>
            </p>
            <p class="flex mb-6" v-if="vacancySingle.phone">
              <i class="mr-1 text-green-500 ri-phone-fill"></i>
              <a :href="'tel:+55' + vacancySingle.phone">{{
                vacancySingle.phone
              }}</a>
            </p>
            <p class="flex mb-6" v-if="vacancySingle.people_contact">
              <i class="mr-1 text-green-500 ri-contacts-fill"></i>
              {{ vacancySingle.people_contact }}
            </p>
          </div>

          <span class="text-lg text-slate-700 "
            >Periodo de Inscrição: (status: {{ vacancySingle.status }})</span
          >
          <p class="mb-6">
            {{ vacancySingle.validity_from }} à {{ vacancySingle.validity_to }}
          </p>
          <span class="text-lg text-slate-700">Atribuições:</span>
          <ul class="mb-6 w-5/6">
            {{
              vacancySingle.description_vacancy
            }}
          </ul>
          <span class="text-lg text-slate-700">Requisitos Necessários:</span>
          <ul>
            {{
              vacancySingle.requirements
            }}
            <!-- <li>Ensino Médio Completo;</li>
            <li>Disponibilidade para trabalhar em turnos.</li> -->
          </ul>
        </div>
        <div class="my-6">
          <h3 class="text-xl mb-3">Benefícios</h3>
          <ul
            class="flex flex-row flex-wrap justify-start items-center w-full"
            v-for="benefit in benefits"
            :key="benefit.label"
          >
            <li class="p-3">
              <span><i class="text-green-500"></i></span>
              {{ benefit.label }}
            </li>
            <!-- <li class="p-3">
              <span><i class="ri-bus-2-fill text-green-500"></i></span> Vale
              transportea
            </li>
            <li class="p-3">
              <span><i class="ri-gas-station-fill text-green-500"></i></span>
              Vale combustível
            </li>
            <li class="p-3">
              <span><i class="ri-roadster-fill text-green-500"></i></span>
              Automóvel
            </li>
            <li class="p-3">
              <span><i class="ri-hospital-fill text-green-500"></i></span>
              Assistência médica
            </li>
            <li class="p-3">
              <span><i class="ri-home-smile-fill text-green-500"></i></span>
              Plano odontológico
            </li>
            <li class="p-3">
              <span><i class="ri-shopping-cart-fill text-green-500"></i></span>
              Vale-alimentação
            </li>
            <li class="p-3">
              <span><i class="ri-restaurant-fill text-green-500"></i></span>
              Vale-refeição
            </li>
            <li class="p-3">
              <span><i class="ri-restaurant-2-fill text-green-500"></i></span>
              Alimentação no local
            </li>
            <li class="p-3">
              <span><i class="ri-handbag-fill text-green-500"></i></span> Cesta
              básica
            </li>
            <li class="p-3">
              <span><i class="ri-open-arm-fill text-green-500"></i></span>
              Férias do colaborador
            </li>
            <li class="p-3">
              <span><i class="ri-parent-fill text-green-500"></i></span>
              Auxílio-Creche
            </li>
            <li class="p-3">
              <span><i class="ri-pie-chart-2-fill text-green-500"></i></span>
              Participação nos lucros
            </li>
            <li class="p-3">
              <span><i class="ri-book-open-fill text-green-500"></i></span>
              Universidades internas
            </li>
            <li class="p-3">
              <span
                ><i class="ri-question-answer-fill text-green-500"></i
              ></span>
              Curso de idiomas
            </li>
            <li class="p-3">
              <span><i class="ri-chat-history-fill text-green-500"></i></span>
              Horários flexíveis
            </li> -->
          </ul>
        </div>
      </div>
      <div class="w-10/12 md:w-4/12 mx-auto">
        <div class="my-5">
          <iframe
            :src="maps"
            allowfullscreen=""
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            width="100%"
            height="300"
          ></iframe>
          <button
            v-if="
              vacancySingle.link_extern_option == 0 &&
                isSubscrible &&
                (user.role.name === 'participant' ||
                  user.role.name === 'Instructor')
            "
            class="w-full my-3 p-1 rounded-full border-2 bg-green-500 text-white font-bold hover:bg-white hover:text-green-500 hover:border-green-1002"
            @click="subscription"
            :class="[
              vacancySingle.status != 'Em inscrição'
                ? 'focus:outline-none disabled:opacity-25'
                : ''
            ]"
            :disabled="vacancySingle.status != 'Em inscrição'"
          >
            Inscreva-se
          </button>
          <button
            v-if="vacancySingle.link_extern_option == 0 && !isSubscrible"
            class="w-full my-3 p-1 rounded-full border-2 bg-red-700 text-white font-bold hover:bg-white hover:text-red-500 hover:border-red-500"
            @click="unSubscribe"
            :class="[
              vacancySingle.status != 'Em inscrição'
                ? 'focus:outline-none disabled:opacity-25'
                : ''
            ]"
            :disabled="vacancySingle.status != 'Em inscrição'"
          >
            Cancelar inscrição
          </button>
          <a
            v-if="vacancySingle.link_extern_option == 1"
            @click.prevent="subscriptionVacancyExtern"
            class="cursor-pointer"
          >
            <div
              class="w-full my-3 p-1 rounded-full border-2 bg-green-500 text-center text-white font-bold hover:bg-white hover:text-green-500 hover:border-green-1002"
            >
              Acessar o Site da Empresa
            </div>
          </a>
        </div>
      </div>
    </div>
    <div
      class="container mx-auto flex flex-wrap"
      v-if="
        upSells[0] != '' &&
          (user.role.name === 'participant' || user.role.name === 'Instructor')
      "
    >
      <div class="w-10/12 md:w-6/12 mx-auto">
        <h3 class="text-2xl my-5">
          Disponilbilizamos cursos para você se qualificar e ter mais chances de
          ser aprovado no processo seletivo. Aproveite!
        </h3>
      </div>
      <div class="w-full">
        <div class="flex overflow-x-auto justify-between h-auto">
          <div
            class="w-10/12 md:w-4/12 border-2 rounded-lg border-green-1002 p-3 m-8 flex-1"
            v-for="upsell in upSells[0]"
            :key="upsell.id"
          >
            <div class="h-full flex items-start">
              <div class="flex-col w-full">
                <div class="flex-grow">
                  <a class="inline-flex"
                    ><span class="title-font font-medium text-gray-900"
                      >Curso:
                      <span class="title-font font-medium text-gray-900">
                        {{ upsell.name }}
                      </span></span
                    ></a
                  ><br /><a class="inline-flex"
                    ><span class="title-font font-medium text-gray-900"
                      >Cidade:
                      <span class="title-font font-medium text-gray-900">
                        {{ upsell.city }}
                      </span></span
                    ></a
                  ><br /><a class="inline-flex"
                    ><span class="title-font font-medium text-gray-900"
                      >Período de:
                      <span class="title-font font-medium text-gray-900">
                        {{ upsell.started_at }} a {{ upsell.ended_at }}
                      </span></span
                    ></a
                  ><br /><a class="inline-flex"
                    ><span class="title-font font-medium text-gray-900"
                      >Informações:
                      <span class="title-font font-medium text-gray-900">
                        {{ upsell.telephone }}
                      </span></span
                    ></a
                  ><br /><a class="inline-flex"
                    ><span class="title-font font-medium text-gray-900"
                      >Email:
                      <span class="title-font font-medium text-gray-900">
                        {{ upsell.email }}
                      </span></span
                    ></a
                  ><br /><br /><button
                    type="button"
                    class="hover:bg-green-500 hover:text-white hover:border-green-1002 relative inline-block flex-shrink-0 h-10 w-60 border-2 border-2 rounded-lg border-green-1002 rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
                    @click="newInterest(upsell)"
                  >
                    Tenho Interesse
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../../layouts/DashboardLayout';
import { mapGetters } from 'vuex';
import axios from '@/utils/axios';
import moment from 'moment';
import LoaderAnimation from '../../../../components/LoaderAnimation.vue';

export default {
  name: 'Single',

  title() {
    return `${process.env.VUE_APP_NAME} | Vaga`;
  },

  components: {
    DashboardLayout,
    LoaderAnimation
  },

  data() {
    return {
      isLoading: false,
      maps: '',
      vacancySingle: {
        id: null,
        office: '',
        description_vacancy: '',
        requirements: '',
        vacancies_available: '',
        contracting_regime: '',
        hiring_time: '',
        office_hours: '',
        salary: '',
        salary_until: '',
        schooling: '',
        validity_from: '',
        validity_to: '',
        status: '',
        visible: 1,
        company_id: null,
        company_name: '',
        email: '',
        link_extern_option: null,
        link_vancancy_extern: '',
        people_contact: '',
        site: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: {
          id: null,
          name: ''
        },
        state: '',
        cep: ''
      },
      telephoneParticipant: '',
      benefits: [],
      upSells: [],
      isSubscrible: 1
    };
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user',
      isAvailable: 'isAvailable'
    })
  },

  // props: {
  //   participant: {
  //     required: true,
  //     type: Number
  //   }
  // },

  methods: {
    async fetchVacancySingle() {
      this.isLoading = true;
      const response = await axios.get(`/api/vagas/${this.$attrs.id}/vaga`);
      response.data.data.forEach(vacancy => {
        this.vacancySingle.office = vacancy.office;
        this.vacancySingle.description_vacancy = vacancy.description_vacancy;
        this.vacancySingle.requirements = vacancy.requirements;
        this.vacancySingle.vacancies_available = vacancy.vacancies_available;
        this.vacancySingle.contracting_regime = vacancy.contracting_regime;
        this.vacancySingle.hiring_time = vacancy.hiring_time;
        this.vacancySingle.office_hours = vacancy.office_hours;
        this.vacancySingle.salary = vacancy.salary;
        this.vacancySingle.salary_until = vacancy.salary_until;
        this.vacancySingle.schooling = vacancy.schooling;
        this.vacancySingle.validity_from = vacancy.validity_from;
        this.vacancySingle.validity_to = vacancy.validity_to;
        this.vacancySingle.status = vacancy.status;
        this.vacancySingle.company_name = vacancy.company_name;
        this.vacancySingle.email = vacancy.email;
        this.vacancySingle.link_vancancy_extern = vacancy.link_vancancy_extern;
        this.vacancySingle.link_extern_option = vacancy.link_extern_option;
        this.vacancySingle.people_contact = vacancy.people_contact;
        this.vacancySingle.phone = vacancy.phone;
        this.vacancySingle.site = vacancy.site;
        this.vacancySingle.address = vacancy.address;
        this.vacancySingle.number = vacancy.number;
        this.vacancySingle.complement = vacancy.complement;
        this.vacancySingle.neighborhood = vacancy.neighborhood;
        this.vacancySingle.city.id = vacancy.city.id;
        this.vacancySingle.city.name = vacancy.city.name;
        this.vacancySingle.state = vacancy.state;
        this.vacancySingle.cep = vacancy.cep;
      });
      this.isLoading = false;

      this.fechUpsell();
    },

    async fecthBenefits() {
      const response = await axios.post(
        `/api/vagas/${this.$attrs.id}/beneficio-por-vaga`
      );

      response.data.forEach(benefits => {
        this.benefits.push({
          label: benefits.name
        });
      });
    },

    async fechUpsell() {
      const response = await axios.get(`/api/vagas/${this.$attrs.id}/upsell/`);
      this.upSells.push(response.data.data);
    },

    async fetchParticipant() {
      const participant = await axios.get('/api/perfil');
      if (participant.data.data) {
        this.telephoneParticipant =
          participant.data.data.cellphone == ''
            ? participant.data.data.telephone
            : participant.data.data.cellphone;
      }
    },

    googleMaps() {
      setTimeout(() => {
        this.maps = `https://www.google.com/maps/embed/v1/place?key=AIzaSyAmaCEeA27tMHR1zG5Ve7sFg4I9CL0wEvk&q='${this.vacancySingle.neighborhood},${this.vacancySingle.city.name}-MT'&zoom=11`;
      }, 1000);
    },

    subscriptionVacancyExtern() {
      this.$confirm({
        message:
          'Você será direcionado para Site da Empresa contratante e sua inscrição e processo Seletivo deve ser feito por lá',
        button: {
          no: 'Cancelar',
          yes: 'OK'
        },
        callback: confirm => {
          if (confirm) {
            axios.post('/api/vagas/inscricao', {
              participant: this.user.participant_id,
              vacancy: this.$attrs.id,
              status: 99
            });
            console.log(this.vacancySingle.link_vancancy_extern);
            window.open(this.vacancySingle.link_vancancy_extern, '_blank');
          }
        }
      });
    },

    subscription() {
      this.$confirm({
        message: 'Tem certeza que deseja fazer parte desse processo?',
        button: {
          no: 'Não',
          yes: 'Sim'
        },
        callback: confirm => {
          if (confirm) {
            if (this.user.role.name === 'Instructor') {
              axios
                .post('/api/vagas/inscricao-instructor', {
                  participant: this.user.id,
                  vacancy: this.$attrs.id
                })
                .then(({ data }) => {
                  this.$toast.success(data.data.message);
                  this.$router.push({ name: 'my-vacancies' });
                });
            } else {
              axios
                .post('/api/vagas/inscricao', {
                  participant: this.user.participant_id,
                  vacancy: this.$attrs.id
                })
                .then(({ data }) => {
                  this.$toast.success(data.data.message);
                  this.$router.push({ name: 'my-vacancies' });
                });
            }
          }
        }
      });
    },

    isSubscription() {
      if (this.user.role.name === 'Instructor') {
        axios
          .post('/api/vagas/inscricao-instructorview', {
            participant: this.user.id,
            vacancy: this.$attrs.id
          })
          .then(({ data }) => {
            if (data == 1) {
              this.isSubscrible = 0;
            }
          });
      } else {
        axios
          .post('/api/vagas/inscricao-participante', {
            participant: this.user.participant_id,
            vacancy: this.$attrs.id
          })
          .then(({ data }) => {
            if (data == 1) {
              this.isSubscrible = 0;
            }
          });
      }
    },

    unSubscribe() {
      this.$confirm({
        message: 'Tem certeza que deseja cancelar sua inscrição?',
        button: {
          no: 'Não',
          yes: 'Sim'
        },
        callback: confirm => {
          if (confirm) {
            const subscribleEnd = moment(
              this.vacancySingle.validity_to,
              'DD/MM/YYYY'
            ).format('YYYY-MM-DD');
            const today = moment(new Date()).format('YYYY-MM-DD');

            if (
              subscribleEnd >= today &&
              this.vacancySingle.status === 'Em inscrição'
            ) {
              if (this.user.role.name === 'Instructor') {
                axios
                  .post('/api/vagas/inscricao-cancelar-instructor', {
                    participant: this.user.id,
                    vacancy: this.$attrs.id
                  })
                  .then(({ data }) => {
                    this.isSubscrible = 0;
                    this.$toast.success(data.data.message);
                    this.$router.push({ name: 'search-vacancies' });
                  });
              } else {
                axios
                  .post('/api/vagas/inscricao-cancelar', {
                    participant: this.user.participant_id,
                    vacancy: this.$attrs.id
                  })
                  .then(({ data }) => {
                    this.isSubscrible = 0;
                    this.$toast.success(data.data.message);
                    this.$router.push({ name: 'search-vacancies' });
                  });
              }
            } else {
              this.$toast.warning('Periodo de cancelamento já passou');
            }
          }
        }
      });
    },

    emailSucess(mensagem) {
      this.$confirm({
        title: 'Informação',
        message: mensagem,
        button: {
          yes: 'OK'
        },
        callback: confirm => {
          if (confirm) {
            this.$router.go();
          }
        }
      });
    },

    newInterest(information) {
      this.$confirm({
        message:
          'Será enviado um email para o sindicado com seu interesse de participar deste curso. Confirma o interesse?',
        button: {
          no: 'Não',
          yes: 'Sim'
        },
        callback: confirm => {
          if (confirm) {
            if (this.user.role.name !== 'Instructor') {
              this.isLoading = true;
              axios
                .post(`/api/participar-evento/${this.user.participant_id}`, {
                  participant_id: this.user.participant_id,
                  course_schedule_id: information.id,
                  name: this.user.name,
                  telephone_participant: this.telephoneParticipant,
                  email_participant: this.user.email,
                  course: information.name,
                  date: `${information.started_at} a ${information.ended_at}`,
                  city: information.city,
                  telephone_syndicate: information.telephone,
                  email_syndicate: information.email
                })
                .then(({ data }) => {
                  this.isLoading = false;
                  this.emailSucess(data.message);
                })
                .catch(({ response }) => {
                  Object.values(response.data.errors).forEach(error => {
                    this.$toast.error(...error);
                  });
                });
            } else {
              this.emailSucess('E-mail enviado ao sindicato rural com sucesso');
            }
          }
        }
      });
    }
  },

  created() {
    this.fetchVacancySingle();
    this.fecthBenefits();
    this.googleMaps();
    this.isSubscription();
    this.fetchParticipant();
  }
};
</script>

<style scoped></style>
